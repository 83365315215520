import React from "react"

import queryString from "query-string"
import SyntaxHighlighter from "react-syntax-highlighter"
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs"
import { Spin, Col, Row, message, Icon, Tooltip } from "antd"
import { injectIntl } from "gatsby-plugin-intl"

import Template from "../../layouts/base"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import withAuth from "../../components/withAuthHoc"

import authFetch from "../../services/network"
import * as Network from "../../services/network"

const NRT_ENDPOINTS_KEYS = [
  "AmqpEndpoints",
  "MqttEndpoints",
  "OpenWireEndpoints",
  "StompEndpoints",
  "WssEndpoints",
]

const BROKER_KEY_LABELS = {
  BrokerUser: "Username",
  BrokerPass: "Password",
  BrokerQueue: "Topic",
}

class IngestCreated extends React.Component {
  constructor(props) {
    super(props)

    const _thatIntl = this.props.intl

    this.ingestNamesTypes = {
      REAL_TIME: _thatIntl.formatMessage({ id: "new-ingestion-rt-title" }),
      NEAR_REAL_TIME: _thatIntl.formatMessage({
        id: "new-ingestion-nrt-title",
      }),
      BATCH: _thatIntl.formatMessage({ id: "new-ingestion-batch-title" }),
      METADATA: _thatIntl.formatMessage({ id: "new-ingestion-metadata-title" }),
    }

    const args = queryString.parse(props.location.search)
    this.codeIngestType = args.t
    this.ingestId = args.id

    this.state = {
      // Input parameters
      status: "LOADING",
      content: {},
      metadata: {
        outputs: {
          topic: "",
          endpoint: "",
        },
      },
      displayAccumulatorMessage: false,
    }

    this._renderAccumulatorAdvice = this._renderAccumulatorAdvice.bind(this)
  }

  componentDidMount() {
    // Parte 1: Obtener certificados ///////////////////////////////////////////////////////////////////////////////////
    let promise
    const payload = JSON.stringify({ id: this.ingestId })

    switch (this.codeIngestType) {
      case "REAL_TIME":
        promise = this.grabCredentials(
          `${process.env.GATSBY_CONF_API_URL}/certificates`,
          payload
        )
        break

      case "NEAR_REAL_TIME":
        promise = this.grabCredentials(
          `${process.env.GATSBY_CONF_API_URL}/nrt-credentials`,
          payload
        )
        break

      case "BATCH":
      case "METADATA":
      default:
        promise = this.grabCredentials(
          `${process.env.GATSBY_CONF_API_URL}/credentials`,
          payload
        )
    }

    promise
      .then(response => {
        if (response.status !== 200) {
          message.error(
            this.props.intl.formatMessage({
              id: "page-new-ingestion-download-get-credentials-ko",
            })
          )
          console.error(
            `[ERROR]: Getting list of credentials: Error Code: ${response.status}\n${response}`
          )
        } else {
          // Examine the text in the response
          response.json().then(content => {
            this.setState({
              content,
              status: "OK",
            })
          })
        }
      })
      .catch(reason => {
        message.error(
          this.props.intl.formatMessage({
            id: "page-new-ingestion-download-get-entities-ko",
          })
        )
        console.error(`[ERROR]: Getting list of entities: ${reason}`)
      })

    // Parte 2: Obtener datos de la ingesta ////////////////////////////////////////////////////////////////////////////
    this.grabIngestData(
      `${process.env.GATSBY_CONF_API_URL}/ingests/${this.ingestId}`
    ).then(response => {
      if (response.status !== 200) {
        message.error(
          this.props.intl.formatMessage({
            id: "page-new-ingestion-download-get-ingest-ko",
          })
        )
        console.error(
          `[ERROR]: Getting ingest's data: Error Code: ${response.status}\n${response}`
        )
      } else {
        // Examine the text in the response
        response.json().then(reply => {
          this.setState({ metadata: reply.item })
        })
      }
    })

    // Parte 3: Mostrar mensaje de acumulador, si procede
    if (
      typeof window !== "undefined" &&
      window.location &&
      window.location.search
    ) {
      const urlParams = new URLSearchParams(window.location.search)
      const accumulator = urlParams.get("accumulator")

      this.setState({
        displayAccumulatorMessage: accumulator && accumulator === "true",
      })
    }
  }

  grabCredentials(uri, payload) {
    return authFetch(uri, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: payload,
    })
  }

  grabIngestData(uri) {
    return authFetch(uri, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
  }

  _renderAccumulatorAdvice() {
    return this.state.displayAccumulatorMessage ? (
      <div className="ingest-download-accumulator-advice">
        <h4>
          <Icon type="warning" style={{ marginRight: "5px" }} />
          {this.props.intl.formatMessage({
            id: "page-new-ingestion-download-accumulator-title",
          })}
        </h4>
        <p>
          {this.props.intl.formatMessage({
            id: "page-new-ingestion-download-accumulator-messsage",
          })}
        </p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/help/limits"
          style={{ fontSize: "1srem" }}
        >
          {this.props.intl.formatMessage({ id: "new-ingestion-more-info" })}
        </a>
      </div>
    ) : null
  }

  render() {
    const _thatIntl = this.props.intl

    return (
      <Template selected={["ingest", "ingest-new"]}>
        <CustomBreadcrumb
          crumbs={[
            _thatIntl.formatMessage({ id: "models-ingestion" }),
            _thatIntl.formatMessage({
              id: "new-ingestion-breadcrumb-creating",
            }),
            `${
              this.ingestNamesTypes[this.codeIngestType]
            } (${_thatIntl.formatMessage({
              id: "page-new-ingestion-download-breadcrumb",
            })})`,
          ]}
        />

        <Row>
          <Col className="intro">
            <h1>
              {this.props.intl.formatMessage({
                id: "page-new-ingestion-download-credentials-title",
              })}
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: _thatIntl.formatMessage({
                  id: "page-new-ingestion-download-credentials-intro",
                }),
              }}
            />
          </Col>
        </Row>

        <div className="content">
          <Row type="flex" justify="center" align="top">
            <Col span={24}>
              {/* Initial Status Loading /////////////////////////////////////////////////////////////////////////// */}
              {this.state.status === "LOADING" && (
                <>
                  <Col span={12} style={{ padding: "10em" }}>
                    <Spin size="large" />
                    {this.props.intl.formatMessage({
                      id: "page-new-ingestion-download-loading-status",
                    })}
                  </Col>
                </>
              )}

              {/* SHOW DATA  /////////////////////////////////////////////////////////////////////////////////////// */}
              {this.state.status === "OK" &&
                this.codeIngestType === "REAL_TIME" && (
                  <>
                    <h3>
                      {_thatIntl.formatMessage({
                        id: "page-new-ingestion-download-result-rt-header",
                      })}
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _thatIntl.formatMessage({
                          id: "page-new-ingestion-download-result-rt-intro",
                        }),
                      }}
                    />
                    {this._renderAccumulatorAdvice()}
                    <h5>
                      <Tooltip
                        title={this.props.intl.formatMessage({
                          id: "page-new-ingestion-download-result-rt-tootlip",
                        })}
                      >
                        <Icon type="info-circle" />
                      </Tooltip>{" "}
                      {this.props.intl.formatMessage({
                        id: "page-new-ingestion-download-rt-access-data",
                      })}
                    </h5>
                    {this.state.displayAccumulatorMessage ? (
                      <h6
                        style={{ color: "#000000", fontSize: "14px" }}
                        dangerouslySetInnerHTML={{
                          __html: _thatIntl.formatMessage({
                            id:
                              "page-new-ingestion-download-rt-accumulator-advice",
                          }),
                        }}
                      />
                    ) : null}
                    <ul>
                      <li>
                        <b>
                          {this.props.intl.formatMessage({
                            id: "page-new-ingestion-download-rt-endpoint",
                          })}
                          :
                        </b>{" "}
                        {(this.state.metadata.outputs &&
                          this.state.metadata.outputs.endpoint) ||
                          this.props.intl.formatMessage({
                            id: "page-new-ingestion-download-rt-generating",
                          })}
                      </li>
                      <li>
                        <b>
                          {this.props.intl.formatMessage({
                            id: "page-new-ingestion-download-rt-topic",
                          })}
                          :
                        </b>{" "}
                        {(this.state.metadata.outputs &&
                          this.state.metadata.outputs.topic) ||
                          this.props.intl.formatMessage({
                            id: "page-new-ingestion-download-rt-generating",
                          })}
                      </li>
                    </ul>
                    <h5>
                      {this.props.intl.formatMessage({
                        id:
                          "page-new-ingestion-download-rt-security-credentials",
                      })}
                    </h5>
                    <ul>
                      <li>
                        <a
                          href={
                            this.state.content["s3-certificates-params"]
                              .certificate_pem_file
                          }
                        >
                          {this.props.intl.formatMessage({
                            id: "page-new-ingestion-download-rt-cert-file-link",
                          })}
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            this.state.content["s3-certificates-params"]
                              .public_key
                          }
                        >
                          {this.props.intl.formatMessage({
                            id:
                              "page-new-ingestion-download-rt-public-key-file-link",
                          })}
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            this.state.content["s3-certificates-params"]
                              .private_key
                          }
                        >
                          {this.props.intl.formatMessage({
                            id:
                              "page-new-ingestion-download-rt-private-key-file-link",
                          })}
                        </a>
                      </li>
                    </ul>
                  </>
                )}

              {this.state.status === "OK" &&
                this.codeIngestType === "NEAR_REAL_TIME" && (
                  <>
                    <h3>
                      {_thatIntl.formatMessage({
                        id: "page-new-ingestion-download-result-rt-header",
                      })}
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _thatIntl.formatMessage({
                          id: "page-new-ingestion-download-result-nrt-intro",
                        }),
                      }}
                    />
                    {this._renderAccumulatorAdvice()}

                    <h5>
                      {this.props.intl.formatMessage({
                        id: "page-new-ingestion-download-nrt-endpoints",
                      })}
                      :
                    </h5>
                    <ul>
                      {NRT_ENDPOINTS_KEYS.map(key => (
                        <li>
                          <b>{key.replace(/Endpoints/, " :")}</b>
                          {typeof this.state.content[key] !== "undefined"
                            ? this.state.content[key]
                            : ""}
                        </li>
                      ))}
                    </ul>

                    <h5>
                      {this.props.intl.formatMessage({
                        id: "page-new-ingestion-download-nrt-broker",
                      })}
                    </h5>
                    <ul>
                      {Object.entries(BROKER_KEY_LABELS).map(([key, value]) => (
                        <li>
                          <b>{value + " :"}</b>
                          {this.state.content[key]}
                        </li>
                      ))}
                    </ul>
                  </>
                )}

              {this.state.status === "OK" &&
                ["BATCH", "METADATA"].includes(this.codeIngestType) && (
                  <>
                    <h3>
                      {_thatIntl.formatMessage({
                        id:
                          "page-new-ingestion-download-result-batch-metadata-header",
                      })}
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _thatIntl.formatMessage({
                          id:
                            "page-new-ingestion-download-result-batch-metadata-intro",
                        }),
                      }}
                    />
                    <h5>
                      {this.props.intl.formatMessage({
                        id: "page-new-ingestion-download-rt-access-data",
                      })}
                    </h5>
                    <ul>
                      <li>
                        <li>
                          <a href={this.state.content["credential_url"]}>
                            {this.props.intl.formatMessage({
                              id:
                                "page-new-ingestion-download-rt-cert-file-link",
                            })}
                          </a>
                        </li>
                      </li>
                      <li>
                        <b>
                          {this.props.intl.formatMessage({
                            id: "page-new-ingestion-download-rt-endpoint",
                          })}
                          :
                        </b>{" "}
                        {this.state.content["sftp_endpoint"]}
                      </li>
                      <li>
                        <b>
                          {this.props.intl.formatMessage({
                            id:
                              "page-new-ingestion-download-batch-metadata-username",
                          })}
                          :
                        </b>{" "}
                        {this.state.content["sftp_user"]}
                      </li>
                    </ul>

                    <h5>
                      {this.props.intl.formatMessage({
                        id:
                          "page-new-ingestion-download-batch-metadata-subscription",
                      })}
                      :
                    </h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _thatIntl.formatMessage({
                          id:
                            "page-new-ingestion-download-batch-metadata-subscription-text",
                        }),
                      }}
                    />
                    <h5>
                      {this.props.intl.formatMessage({
                        id:
                          "page-new-ingestion-download-batch-metadata-subscription-connection-example",
                      })}
                      :
                    </h5>
                    <span style={{ maxWidth: "350px" }}>
                      <SyntaxHighlighter
                        language="shell"
                        style={dracula}
                        wrapLines={false}
                      >
                        {`$> chmod 400 cred-${this.state.content["sftp_user"]}-sftp.pem \n$ sftp -i cred-${this.state.content["sftp_user"]}-sftp.pem ${this.state.content["sftp_user"]}@${this.state.content["sftp_endpoint"]} \n > put YOUR_FILES.csv\n$ put -r YOUR_FOLDER`}
                      </SyntaxHighlighter>
                    </span>
                  </>
                )}
            </Col>
          </Row>
          <Row type="flex" justify="center" align="top">
            <Col span={24}>
              <h3>
                {_thatIntl.formatMessage({
                  id: "page-new-ingestion-download-result-api-header",
                })}
              </h3>
              <p>
                {_thatIntl.formatMessage({
                  id: "page-new-ingestion-download-result-api-text",
                })}{" "}
                <ul>
                  <li>
                    {" "}
                    <a
                      href={`https://${Network.generateDeploymentHostname(
                        "developers-portal"
                      )}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {_thatIntl.formatMessage({
                        id: "page-new-ingestion-download-result-api-link",
                      })}
                    </a>
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </div>
      </Template>
    )
  }
}

export default injectIntl(withAuth(IngestCreated))
